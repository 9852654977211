/*=================================================
Class Definitions
=================================================*/

.subscribe-me h1{
	text-transform:uppercase;
	font-weight:700;
	color:#ed1c24;
}

.subscribe-me h2{
	margin-top: 12px;
	font-size: 26px;
	color: #b1b1b1;
	margin-bottom: 35px;
}

.subscribe-me button {
	font-size: 24px;
	font-weight: 700;
	color: #fff;
	background-color: #f14950;
	border: 1px solid #f14950;
	text-transform: uppercase;
	padding: 9px 20px;
	position: absolute;
	right:0px;
	top: 0;
	cursor: pointer;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.subscribe-me button:hover{
	background-color:#ed1c24;
}

.subscribe-me button:focus {
	outline:none;
}

.subscribe-me form {
	max-width:550px;
	width:100%;
	display:inline-block;
}

.subscribe-me .input-group-addon {
	border:1px solid #f2f2f2;
	border-right:0;
	border-radius:0;
    position: absolute;
    height: 100%;
    padding: 15px 15px;	
}

.subscribe-me.sb form input[type='text'] {
	padding-left: 65px;
}

.subscribe-me .input-group {
	position:relative;
}

.subscribe-me input {
	max-width:550px;
}

.subscribe-me.sb form input:focus {
	box-shadow:none;
	outline:none;
}

.sb {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -400px;
  margin-top: -150px;
  width: 800px;
  height: 325px;
  background: white;
  padding: 55px;
  box-shadow: 0 2px 3px rgba(0,0,0,0.5);
  -moz-box-shadow: 0 2px 3px rgba(0,0,0,0.5);
  -webkit-box-shadow: 0 2px 3px rgba(0,0,0,0.5);
  z-index: 1999;
  opacity: 0;
  border-radius:4px;
}

.sb form input[type='text'], .sb form input[type='password'], .sb form input[type='email'], .sb form textarea{
  margin: 0;
  border:1px solid #f2f2f2;
  border-left:0;
  background: #fff;
  padding: 15px;
  font-size: 16px;
  width:100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.sb form input[type='submit']{
  padding: 18px 10px;
  /* background: none; Sonarqube: duplicate tag. Removed this since the next tag will supercede */
  font-size: 16px;
  text-transform: uppercase;
  border: none;
  background: black;
  color: white;
  width: 20%;
  margin: 0;
}

.sb .sb-close-btn {
  position: absolute;
  right: 15px;
  top: 15px;
}

.sb .sb-close-btn img {
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	opacity:0.4;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.sb .sb-close-btn img:hover {
	-webkit-filter: grayscale(0);
	filter: grayscale(0);
	opacity:1;
}

.sb.sb-withoverlay {
  position: absolute;
  top: 200px;
  left: 50%;
  margin-left: -250px;
}


.sb-open.sb-open-with-overlay {
  overflow: hidden;
}

.sb-open .sb-overlay {
  opacity: 1;
}

.sb-open .sb {
  opacity: 1;
}

.sb-overlay {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.58);
  opacity: 0;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  overflow: auto;
}

.sb-close-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

[class^="sb-animation-"], [class*=" sb-animation-"] {
  -webkit-animation-duration: 500ms;
  -moz-animation-duration: 500ms;
  -o-animation-duration: 500ms;
  animation-duration: 500ms;
}

.sb-animation-flyInRight {
  -webkit-animation-name: flyInRight;
	-moz-animation-name: flyInRight;
	-o-animation-name: flyInRight;
	animation-name: flyInRight;
}

.sb-animation-flyOutRight {
  -webkit-animation-name: flyOutRight;
	-moz-animation-name: flyOutRight;
	-o-animation-name: flyOutRight;
	animation-name: flyOutRight;
}

.sb-animation-flyInLeft {
  -webkit-animation-name: flyInLeft;
	-moz-animation-name: flyInLeft;
	-o-animation-name: flyInLeft;
	animation-name: flyInLeft;
}

.sb-animation-flyOutLeft {
  -webkit-animation-name: flyOutLeft;
	-moz-animation-name: flyOutLeft;
	-o-animation-name: flyOutLeft;
	animation-name: flyOutLeft;
}

.sb-animation-flyInUp {
  -webkit-animation-name: flyInUp;
	-moz-animation-name: flyInUp;
	-o-animation-name: flyInUp;
	animation-name: flyInUp;
}

.sb-animation-flyOutUp {
  -webkit-animation-name: flyOutUp;
	-moz-animation-name: flyOutUp;
	-o-animation-name: flyOutUp;
	animation-name: flyOutUp;
}

.sb-animation-flyInDown {
  -webkit-animation-name: flyInDown;
	-moz-animation-name: flyInDown;
	-o-animation-name: flyInDown;
	animation-name: flyInDown;
}

.sb-animation-flyOutDown {
  -webkit-animation-name: flyOutDown;
	-moz-animation-name: flyOutDown;
	-o-animation-name: flyOutDown;
	animation-name: flyOutDown;
}
/*=================================================
Animation Library
=================================================*/

@-webkit-keyframes flyInRight {
	0% {
		opacity: 0;
		-webkit-transform: translateX(3000px);
	}

	100% {
		-webkit-transform: translateX(0);
	}
}

@-moz-keyframes flyInRight {
	0% {
		opacity: 0;
		-moz-transform: translateX(3000px);
	}

	100% {
		-moz-transform: translateX(0);
	}
}

@-o-keyframes flyInRight {
	0% {
		opacity: 0;
		-o-transform: translateX(3000px);
	}

	100% {
		-o-transform: translateX(0);
	}
}

@keyframes flyInRight {
	0% {
		opacity: 0;
		transform: translateX(3000px);
	}

	100% {
		transform: translateX(0);
	}
}

@-webkit-keyframes flyOutRight {
	0% {
		-webkit-transform: translateX(0);
	}

	100% {
	  opacity: 0;
		-webkit-transform: translateX(3000px);
	}
}

@-moz-keyframes flyOutRight {
	0% {
		-moz-transform: translateX(0);
	}

	100% {
	  opacity: 0;
		-moz-transform: translateX(3000px);
	}
}

@-o-keyframes flyOutRight {
	0% {
		-o-transform: translateX(0);
	}

	100% {
	  opacity: 0;
		-o-transform: translateX(3000px);
	}
}

@keyframes flyOutRight {
	0% {
		transform: translateX(0);
	}

	100% {
	  opacity: 0;
		transform: translateX(3000px);
	}
}

@-webkit-keyframes flyInLeft {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-3000px);
	}

	100% {
		-webkit-transform: translateX(0);
	}
}

@-moz-keyframes flyInLeft {
	0% {
		opacity: 0;
		-moz-transform: translateX(-3000px);
	}

	100% {
		-moz-transform: translateX(0);
	}
}

@-o-keyframes flyInLeft {
	0% {
		opacity: 0;
		-o-transform: translateX(-3000px);
	}

	100% {
		-o-transform: translateX(0);
	}
}

@keyframes flyInLeft {
	0% {
		opacity: 0;
		transform: translateX(-3000px);
	}

	100% {
		transform: translateX(0);
	}
}
@-webkit-keyframes flyOutLeft {
	0% {
		-webkit-transform: translateX(0);
	}

	100% {
		opacity: 0;
		-webkit-transform: translateX(-3000px);
	}
}

@-moz-keyframes flyOutLeft {
	0% {
		-moz-transform: translateX(0);
	}

	100% {
		opacity: 0;
		-moz-transform: translateX(-3000px);
	}
}

@-o-keyframes flyOutLeft {
	0% {
		-o-transform: translateX(0);
	}

	100% {
		opacity: 0;
		-o-transform: translateX(-3000px);
	}
}

@keyframes flyOutLeft {
	0% {
		transform: translateX(0);
	}

	100% {
		opacity: 0;
		transform: translateX(-3000px);
	}
}

@-webkit-keyframes flyInUp {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-2000px);
	}

	100% {
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes flyInUp {
	0% {
		opacity: 0;
		-moz-transform: translateY(-2000px);
	}

	100% {
		-moz-transform: translateY(0);
	}
}

@-o-keyframes flyInUp {
	0% {
		opacity: 0;
		-o-transform: translateY(-2000px);
	}

	100% {
		-o-transform: translateY(0);
	}
}

@keyframes flyInUp {
	0% {
		opacity: 0;
		transform: translateY(-2000px);
	}

	100% {
		transform: translateY(0);
	}
}

@-webkit-keyframes flyOutUp {
	0% {
		-webkit-transform: translateY(0);
	}

	100% {
		opacity: 0;
		-webkit-transform: translateY(-2000px);
	}
}

@-moz-keyframes flyOutUp {
	0% {
		-moz-transform: translateY(0);
	}

	100% {
		opacity: 0;
		-moz-transform: translateY(-2000px);
	}
}

@-o-keyframes flyOutUp {
	0% {
		-o-transform: translateY(0);
	}

	100% {
		opacity: 0;
		-o-transform: translateY(-2000px);
	}
}

@keyframes flyOutUp {
	0% {
		transform: translateY(0);
	}

	100% {
		opacity: 0;
		transform: translateY(-2000px);
	}
}


@-webkit-keyframes flyInDown {
	0% {
		opacity: 0;
		-webkit-transform: translateY(2000px);
	}

	100% {
		-webkit-transform: translateY(0);
	}
}

@-moz-keyframes flyInDown {
	0% {
		opacity: 0;
		-moz-transform: translateY(2000px);
	}

	100% {
		-moz-transform: translateY(0);
	}
}

@-o-keyframes flyInDown {
	0% {
		opacity: 0;
		-o-transform: translateY(2000px);
	}

	100% {
		-o-transform: translateY(0);
	}
}

@keyframes flyInDown {
	0% {
		opacity: 0;
		transform: translateY(2000px);
	}

	100% {
		transform: translateY(0);
	}
}

@-webkit-keyframes flyOutDown {
	0% {
		-webkit-transform: translateY(0);
	}

	100% {
		opacity: 0;
		-webkit-transform: translateY(2000px);
	}
}

@-moz-keyframes flyOutDown {
	0% {
		-moz-transform: translateY(0);
	}

	100% {
		opacity: 0;
		-moz-transform: translateY(2000px);
	}
}

@-o-keyframes flyOutDown {
	0% {
		-o-transform: translateY(0);
	}

	100% {
		opacity: 0;
		-o-transform: translateY(2000px);
	}
}

@keyframes flyOutDown {
	0% {
		transform: translateY(0);
	}

	100% {
		opacity: 0;
		transform: translateY(2000px);
	}
}
